body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home{
  margin-left: 900px;
}

#logo{
  display: flex;
  align-items: flex-start;
  margin-top: -260px;
   

}

#img2{
  margin-left: 800px;
  margin-top: -280px;
}

#intro{
  margin-left: 50px;
  font-size: 20px;
 
}

#intro2{
font-style: italic;
font-family: 'Courier New', Courier, monospace;
font-size: 15px;
 
}

#save{
  font-size: 40px;
  margin-left: 50px;
  margin-top: -120px;
  font-style: italic;

}


#save1{
  font-size: 40px;  
  margin-left: 50px;
  margin-top: 40px;
  font-style: italic;
}

#secondDiv {

  background-color: white;
  padding-bottom: 35px;
  padding-top: 25px;
  margin-top: -50px;


}  

#secondDiv h1, p{
  margin-left: 50px;
  margin-top: 25px;
  
}

#img3{
  margin-left: 1000px;
  margin-top: -250px;
}

#thirdDiv{
  background: hsla(0, 0%, 100%, 1);

  background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(111, 100%, 92%, 1) 100%);
  
  background: -moz-linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(111, 100%, 92%, 1) 100%);
  
  background: -webkit-linear-gradient(0deg, hsla(0, 0%, 100%, 1) 0%, hsla(111, 100%, 92%, 1) 100%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FFFFFF", endColorstr="#DEFFD8", GradientType=1 );

padding-bottom: 100px;

text-align: center;
}

#howItWorks h1{
  text-align: center;
  margin-top: 0px; 
 

}

#howItWorks2{
  text-align: center;
  margin-top: 40px;
}

#list1{
  text-align: center;
  margin-top: 50px;
  vertical-align: middle;
}

#pic1{
  width: 100px;
}

#left-side{
  float: left;
  max-width: 115%;
  max-height:15%;
}

#images{
  float: left;
  max-width: 95%;
  max-height:15%;
  padding-left: 50;
}

#right-side{
  float: right;
  font-size: 15px;
  padding-left: 90px;
  padding-top: -0%;
 
}

.container{
  display: flex;
  align-items: center;
  justify-content: center;
}

body{
  background-color: #EFE6B3;
  }

  .column {
    float: left;
    width: 50%;
    padding: 5px;
  }
  
  /* Clear floats after image containers */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  /* styles.css */

.footer-container {
  background-color: #EFE6B3;
  color: black;
  padding: 20px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.container {
  flex: 1; /* This ensures that the container takes up the remaining space. */
}

/* ImageSlideshow.css */

.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 1000px; /* Set the maximum width of the slideshow */
  margin: auto;
}

.slide {
  display: none; /* Hide all slides by default */
  width: 100%;
  opacity: 0.7; /* Set initial opacity */
  transition: opacity 0.6s; /* Smooth transition for opacity */
}

.slide.active {
  display: block; /* Display active slide */
  opacity: 1; /* Full opacity for the active slide */
}

.image-slide {
  width: 70%;
  height: 50%; /* Maintain aspect ratio */
}

/* You can add more styling as needed, such as navigation arrows, captions, etc. */
